import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ListSpec } from 'state-domains/domain/subscription';

import { buildConfigurationActivityModifyListSpecUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const modifyConfigurationActivityListSpec = (
    urlMap: Record<string, string>,
    payload: any,
): Observable<ListSpec> => {
    const url = buildConfigurationActivityModifyListSpecUrl(urlMap);
    return sendRequestWithXSRFToken(url, payload).pipe(
        switchMap(({ response }: AjaxResponse<ListSpec>) => {
            const convertedResponse = convertToCamel<ListSpec>(response);
            return observableOf(convertedResponse);
        }),
    );
};
